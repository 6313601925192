import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import Link from '../Link';
import Text from '../Text';

const BreadcrumbItem = forwardRef(
  ({ className, children, href = null, ...rest }, ref) => {
    const styles = twMerge(
      'items-center text-ellipsis whitespace-nowrap text-sm no-underline',
      '[&>a]:text-primary-400 [&>a]:no-underline [&>a]:dark:text-primary-200',
      'last:hover:no-underline md:last:overflow-hidden',
      'last:[&>:first-of-type]:overflow-hidden last:[&>:first-of-type]:text-ellipsis last:[&>:first-of-type]:whitespace-nowrap',
      'inline-flex group-[:nth-last-of-type(2)_&]:inline-flex',
      className
    );

    if (href) {
      return (
        <Link
          ref={ref}
          className={twJoin('cursor-pointer', styles)}
          size="size2"
          {...rest}
        >
          {children}
        </Link>
      );
    }

    return (
      <Text ref={ref} className={styles} size="size2" {...rest}>
        {children}
      </Text>
    );
  }
);

BreadcrumbItem.displayName = 'BreadcrumbItem';

BreadcrumbItem.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

export default BreadcrumbItem;
