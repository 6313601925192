import PropTypes from 'prop-types';

import Icon from '../Icon';

function BreadcrumbItemWrapper({ children, unique = false, icon = false }) {
  return (
    <li className={unique ? 'inline' : 'flex items-center'}>
      {children}
      {icon && (
        <Icon
          className="text-neutral-low-300 dark:text-neutral-high-100"
          name="arrow-forward"
          size="size3"
        />
      )}
    </li>
  );
}

BreadcrumbItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.bool,
  unique: PropTypes.bool,
};

export default BreadcrumbItemWrapper;
